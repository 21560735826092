import React from "react";
import { Header, Segment, Icon } from "semantic-ui-react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import GoHomeOrGoHunt from "../components/GoHomeOrGoHunt";

const NotFoundPage = () => (
  <Layout slim>
    <SEO title="404: Not found" />
    <Segment placeholder>
      <Header icon>
        <Icon name="ban" />
        Page Not Found!
      </Header>
      <GoHomeOrGoHunt />
    </Segment>
  </Layout>
);

export default NotFoundPage;
